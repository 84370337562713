<script lang="ts" setup>
import { computed } from 'vue';
import {
	GOTCHA,
	CONGRATS,
	PRIZE_BONUS_MODAL_DESCRIPTION,
	PRIZE_BONUS_MODAL_TITLE_2,
	PRIZE_BONUS_MODAL_COIN_TITLE,
	PRIZE_BONUS_MODAL_COIN_SUBTITLE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { StreakPrizes } from '@/enums';
import money_box_logo from '@/assets/money_box_mock.svg';
import computer_rocket from '@/assets/computer_rocket.svg';
import { useI18n } from 'vue-i18n';
import { localizePoints } from '@/utils/helpers';

const { options } = defineProps<{
	options: {
		isCode: StreakPrizes;
		percentage: number | null;
		coins: number | null;
	};
}>();

defineEmits<{
	'close-modal': [];
}>();

const { t } = useI18n();

const isModalCode = computed(() => options.isCode === StreakPrizes.BONUS);
const icon = computed(() =>
	isModalCode.value ? computer_rocket : money_box_logo
);
const title = computed(() =>
	isModalCode.value
		? t(PRIZE_BONUS_MODAL_TITLE_2, { value: options.percentage })
		: t(PRIZE_BONUS_MODAL_COIN_TITLE, {
				value: localizePoints(options?.coins || 0),
			})
);
const subtitle = computed(() =>
	isModalCode.value
		? t(PRIZE_BONUS_MODAL_DESCRIPTION)
		: t(PRIZE_BONUS_MODAL_COIN_SUBTITLE)
);
</script>

<template>
	<div class="prize-bonus-modal">
		<ModalCommonContent>
			<template #header>{{ $t(CONGRATS) }}</template>
			<template #icon>
				<img :src="icon" class="scale-in" />
			</template>
			<template #title>
				<PrimeText tag="div" weight="500" color="white" size="1xl">
					{{ title }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText tag="div" size="sm" color="grey-700">
					{{ subtitle }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton :label="$t(GOTCHA)" @click="$emit('close-modal')" />
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.prize-bonus-modal {
	:deep(.modal-common-content) {
		.content-description {
			border: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	:deep(.content-icon) {
		width: 180px;
		height: 180px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.125rem;
	}
	:deep(.content-title .p-text) {
		text-align: center;
		max-width: unset;
	}
	:deep(.content-description) {
		margin-bottom: 0;
		padding-bottom: 0;
		border: none;
		.p-text {
			text-align: center;
			max-width: unset;
		}
	}
	:deep {
		.p-modal-bottom {
			border-top: 1px solid var(--extra-grey-900) !important;
			padding-top: 1rem !important;
			padding-bottom: 1rem !important;
		}
	}
	:deep(.content-actions) {
		display: flex;
		justify-content: center;
	}
}
</style>
