import {
	CHARITY,
	GIFT_CARDS,
	PREPAID_CARDS,
	TRANSFER,
} from '@/locales/constants';
import { RewardCategories } from '@/enums';
import type { RewardCategory } from '@/types';
import {
	PiHcTransfers,
	PiHcGift,
	PiHcCard,
	PiHcCharity,
} from '@primeinsightsgroupllc/prime-icons';

export const categories: Record<RewardCategories, RewardCategory> = {
	[RewardCategories.TRANSFERS]: {
		icon: PiHcTransfers,
		name: TRANSFER,
	},
	[RewardCategories.GIFT_CARDS]: {
		icon: PiHcGift,
		name: GIFT_CARDS,
	},
	[RewardCategories.PREPAID_CARDS]: {
		icon: PiHcCard,
		name: PREPAID_CARDS,
	},
	[RewardCategories.CHARITY]: {
		icon: PiHcCharity,
		name: CHARITY,
	},
	[RewardCategories.PROMOTED]: {
		icon: PiHcTransfers,
		name: TRANSFER,
	},
};
